import NotificationService from '../../services/NotificationService'
import router from '../../router'

const state = () => ({
    loadingNotifications: false,
    notification: null,
    notifications: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_notification: false
})
  
const mutations = {
    SET_NOTIFICATION: (state, payload) => {
        state.notification = payload
    },
    SET_NOTIFICATIONS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.notifications = payload.itens
        }else{
            state.notifications = [...state.notifications, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_NOTIFICATIONS: (state, payload) => {
        state.loadingNotification = payload
    },
    SET_SHOW_MODAL_NOTIFICATION: (state, payload) => {
        state.show_modal_new_notification = payload
    },
}

const actions = {
    async fetchNotification({commit}, id){
        try{
            commit('SET_LOADING_NOTIFICATIONS', true)
            const response = await NotificationService.getOne(id);
            const dataNotification = response.data.item
            if(dataNotification){
                dataNotification.createdAt = await formatarData(dataNotification.createdAt);
                dataNotification.updatedAt = await formatarData(dataNotification.updatedAt);
            }
            commit('SET_NOTIFICATION', dataNotification)
            commit('SET_LOADING_NOTIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_NOTIFICATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchNotifications({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_NOTIFICATIONS', true)
            const response = await NotificationService.get(keyword, pageNumber, sortBy);
            commit('SET_NOTIFICATIONS', response.data)
            commit('SET_LOADING_NOTIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_NOTIFICATIONS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createNotification({commit}, data){
        try{
            commit('SET_LOADING_NOTIFICATIONS', true)
            const response = await NotificationService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            const newITem = response.data.item
            if(response && newITem) {
                router.push({path: `/form_notification/${newITem.id}`});
            }
            commit('SET_SHOW_MODAL_NOTIFICATION', false)
            commit('SET_LOADING_NOTIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_NOTIFICATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeNotification({commit}, data){
        try{
            commit('SET_LOADING_NOTIFICATIONS', true)
            const response = await NotificationService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_NOTIFICATIONS', false)
        } catch (error) {
            commit('SET_LOADING_NOTIFICATIONS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

async function formatarData(data) {
    const dataObj = new Date(data);
    const ano = dataObj.getUTCFullYear();
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');
    const hora = dataObj.getUTCHours().toString().padStart(2, '0');
    const minuto = dataObj.getUTCMinutes().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia} ${hora}:${minuto}`;
}

const getters = {
    getNotification: state => state.notification,
    getNotifications: state => state.notifications,
    getLoadingNotification: state => state.loadingNotification,
    getShowModalNewNotification: state => state.show_modal_new_notification
}

export default {
    state,
    getters,
    mutations,
    actions
};