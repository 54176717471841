// import Projects_AutoresService from '../../services/Projects_AutoresService';

const state = () => ({
    loadingProjectsAutores: false,
    show: false,
    newAutor: '',
    projects: JSON.parse(localStorage.getItem('projects')) || null
})
  
const mutations = {
    SHOW_MODAL_AUTORES: (state, boolean) => {
        state.show = boolean
    },
    SET_NEW_AUTOR: (state, autor) => {
        state.newAutor = autor
    },
    SET_LOADING_PROJECTS_AUTORES: (state, payload) => {
        state.loadingProjectsAutores = payload
    }
}

const actions = {
}

const getters = {
    getShowModalNewAutor: state => state.show,
    getNewAutor: state => state.newAutor,
    getLoadingProjectsAutores: state => state.loadingProjectsAutores
}

export default {
    state,
    getters,
    mutations,
    actions
};