// import Projects_AutoresService from '../../services/Projects_AutoresService';

const state = () => ({
    loadingProjectsDisciplina: false,
    show: false,
    newDisciplina: '',
    projects: JSON.parse(localStorage.getItem('projects')) || null
})
  
const mutations = {
    SHOW_MODAL_DISCIPLINA: (state, boolean) => {
        state.show = boolean
    },
    SET_NEW_DISCIPINA: (state, payload) => {
        state.newDisciplina = payload
    },
    SET_LOADING_PROJECTS_AUTORES: (state, payload) => {
        state.loadingProjectsDisciplina = payload
    }
}

const actions = {
}

const getters = {
    getShowModalnewDisciplina: state => state.show,
    getNewDisciplina: state => state.newDisciplina,
    getLoadingProjectsDisciplina: state => state.loadingProjectsDisciplina
}

export default {
    state,
    getters,
    mutations,
    actions
};