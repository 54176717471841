import BannerService from '../../services/BannerService'
import router from '../../router'

const state = () => ({
    loadingBanners: false,
    banner: null,
    banners: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_banner: false
})
  
const mutations = {
    SET_BANNER: (state, payload) => {
        state.banner = payload
    },
    SET_BANNERS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.banners = payload.itens
        }else{
            state.banners = [...state.banners, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_BANNERS: (state, payload) => {
        state.loadingBanner = payload
    },
}

const actions = {
    async fetchBanner({commit}, id){
        try{
            commit('SET_LOADING_BANNERS', true)
            const response = await BannerService.getOne(id);
            var dataBanner = response.data.item;
            if(dataBanner){
                if(dataBanner.expiration){
                    dataBanner.expiration = await formatarData(dataBanner.expiration);
                }
                dataBanner.createdAt = await formatarData(dataBanner.createdAt);
                dataBanner.updatedAt = await formatarData(dataBanner.updatedAt);
            }
            commit('SET_BANNER', dataBanner)
            commit('SET_LOADING_BANNERS', false)
        } catch (error) {
            commit('SET_LOADING_BANNERS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchBanners({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_BANNERS', true)
            const response = await BannerService.get(keyword, pageNumber, sortBy);
            commit('SET_BANNERS', response.data)
            commit('SET_LOADING_BANNERS', false)
        } catch (error) {
            commit('SET_LOADING_BANNERS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createBanner({commit}, data){
        try{
            commit('SET_LOADING_BANNERS', true)
            const response = await BannerService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            router.push({path: `/form_banner/${response.data.item.id}`});
            commit('SET_LOADING_BANNERS', false)
        } catch (error) {
            commit('SET_LOADING_BANNERS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeBanner({commit}, data){
        try{
            commit('SET_LOADING_BANNERS', true)
            const response = await BannerService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_BANNERS', false)
        } catch (error) {
            commit('SET_LOADING_BANNERS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async deleteBanner({state, commit}, id) {
        try{
            commit('SET_LOADING_BANNERS', true);
            if(state.banner.id == id){
                const response = await BannerService.delete(id);
                router.push({path: `/banners`});
                commit('SET_ALERT', {
                    heading: 'success',
                    message: response.data.message
                });
            }
            commit('SET_SHOW_MODAL_CONFIRMATION', false);
            commit('SET_LOADING_BANNERS', false);
        } catch (error) {
            commit('SET_LOADING_BANNERS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    }
}

async function formatarData(data) {
    const dataObj = new Date(data);
    const ano = dataObj.getUTCFullYear();
    const mes = (dataObj.getUTCMonth() + 1).toString().padStart(2, '0');
    const dia = dataObj.getUTCDate().toString().padStart(2, '0');
    const hora = dataObj.getUTCHours().toString().padStart(2, '0');
    const minuto = dataObj.getUTCMinutes().toString().padStart(2, '0');

    return `${ano}-${mes}-${dia} ${hora}:${minuto}`;
}

const getters = {
    getBanner: state => state.banner,
    getBanners: state => state.banners,
    getLoadingBanner: state => state.loadingBanner,
    getShowModalNewBanner: state => state.show_modal_new_banner
}

export default {
    state,
    getters,
    mutations,
    actions
};