import AutorService from '../../services/AutorService'

const state = () => ({
    loadingAutores: false,
    autor: null,
    autores: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_autor: false
})
  
const mutations = {
    SET_AUTOR: (state, payload) => {
        state.autor = payload
    },
    SET_AUTORES: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.autores = payload.itens
        }else{
            state.autores = [...state.autores, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_AUTORES: (state, payload) => {
        state.loadingAutor = payload
    },
    SET_SHOW_MODAL_NEW_AUTOR: (state, payload) => {
        state.show_modal_new_autor = payload
    },
}

const actions = {
    async fetchAutor({commit}, id){
        try{
            commit('SET_LOADING_AUTORES', true)
            const response = await AutorService.getOne(id);
            commit('SET_AUTOR', response.data.item)
            commit('SET_LOADING_AUTORES', false)
        } catch (error) {
            commit('SET_LOADING_AUTORES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchAutores({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_AUTORES', true)
            const response = await AutorService.get(keyword, pageNumber, sortBy);
            commit('SET_AUTORES', response.data)
            commit('SET_LOADING_AUTORES', false)
        } catch (error) {
            commit('SET_LOADING_AUTORES', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createAutor({commit, dispatch, rootState}, data){
        try{
            commit('SET_LOADING_AUTORES', true)
            const response = await AutorService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            if(rootState.aula.aula) rootState.aula.aula.autores.push(response.data.item);
            dispatch('fetchAutores', { keyword: ''})
            commit('SET_SHOW_MODAL_NEW_AUTOR', false)
            commit('SET_LOADING_AUTORES', false)
        } catch (error) {
            commit('SET_LOADING_AUTORES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeAutor({commit}, data){
        try{
            commit('SET_LOADING_AUTORES', true)
            const response = await AutorService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_AUTORES', false)
        } catch (error) {
            commit('SET_LOADING_AUTORES', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getAutor: state => state.autor,
    getAutores: state => state.autores,
    getLoadingAutor: state => state.loadingAutor,
    getShowModalNewAuthor: state => state.show_modal_new_autor
}

export default {
    state,
    getters,
    mutations,
    actions
};