import DisciplinaService from '../../services/DisciplinaService'

const state = () => ({
    loadingDisciplinas: false,
    disciplina: null,
    disciplinas: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_disciplina: false
})
  
const mutations = {
    SET_DISCIPLINA: (state, payload) => {
        state.disciplina = payload
    },
    SET_DISCIPLINAS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.disciplinas = payload.itens
        }else{
            state.disciplinas = [...state.disciplinas, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total
    },
    SET_LOADING_DISCIPLINAS: (state, payload) => {
        state.loadingDisciplina = payload
    },
    SET_SHOW_MODAL_NEW_DISCIPLINA: (state, payload) => {
        state.show_modal_new_disciplina = payload
    },
}

const actions = {
    async fetchDisciplina({commit}, id){
        try{
            commit('SET_LOADING_DISCIPLINAS', true)
            const response = await DisciplinaService.getOne(id);
            commit('SET_DISCIPLINA', response.data.item)
            commit('SET_LOADING_DISCIPLINAS', false)
        } catch (error) {
            commit('SET_LOADING_DISCIPLINAS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchDisciplinas({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_DISCIPLINAS', true)
            const response = await DisciplinaService.get(keyword, pageNumber, sortBy);
            commit('SET_DISCIPLINAS', response.data)
            commit('SET_LOADING_DISCIPLINAS', false)
        } catch (error) {
            commit('SET_LOADING_DISCIPLINAS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async createDisciplina({commit, dispatch, rootState}, data){
        try{
            commit('SET_LOADING_DISCIPLINAS', true)
            const response = await DisciplinaService.create(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            console.log(rootState.aula.aula)
            if(rootState.aula.aula) rootState.aula.aula.disciplina = response.data.item;
            dispatch('fetchDisciplinas', { keyword: ''})
            commit('SET_SHOW_MODAL_NEW_DISCIPLINA', false)
            commit('SET_LOADING_DISCIPLINAS', false)
        } catch (error) {
            commit('SET_LOADING_DISCIPLINAS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updadeDisciplina({commit}, data){
        try{
            commit('SET_LOADING_DISCIPLINAS', true)
            const response = await DisciplinaService.update(data);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_LOADING_DISCIPLINAS', false)
        } catch (error) {
            commit('SET_LOADING_DISCIPLINAS', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getDisciplina: state => state.disciplina,
    getDisciplinas: state => state.disciplinas,
    getLoadingDisciplina: state => state.loadingDisciplina,
    getShowModalNewDisciplina: state => state.show_modal_new_disciplina
}

export default {
    state,
    getters,
    mutations,
    actions
};