import Api from './ApiService'

export default {
    login(credentials) {
        return Api().post('users/login-adm', credentials);
    },
    getUser(){
        return Api().get('users/profile');
    },
    getUsersAulas(keyword, pageNumber, order) {
        return Api().post(`users/aulas?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getUserAvaliacoes(keyword, pageNumber, order) {
        return Api().post(`users/notas?keyword=${keyword}&pageNumber=${pageNumber}&orderColumn=${order.column}&orderSort=${order.sort}`);
    },
    getOne(id) {
        return Api().get(`users/${id}`);
    },
    create(credentials){
        return Api().post('users/signup', credentials);
    },
    updateUser(credentials){
        return Api().put(`users/update/user/${credentials.id}`, credentials);
    },
    auth(){
        return Api().post('users/authenticated');
    }
}