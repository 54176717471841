import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`notifications/adm?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`notifications/adm/${id}`);
    },
    create(data) {
        return Api().post(`notifications`, data);
    },
    update(data) {
        return Api().put(`notifications/${data.id}`, data);
    }
}