import PeriodoService from '../../services/PeriodoService'

const state = () => ({
    loadingPeriodos: false,
    periodo: null,
    periodos: [],
    total: 0,
    pages: 0,
    page: 1,
    show_modal_new_periodo: false
})
  
const mutations = {
    SET_PERIODO: (state, payload) => {
        state.periodo = payload
    },
    SET_PERIODOS: (state, payload) => {
        if(payload.pageNumber == 1) {
            state.periodos = payload.itens
        }else{
            state.periodos = [...state.periodos, ...payload.itens]
        }
        state.page =  payload.pageNumber
        state.pages =  payload.pages
        state.total =  payload.total

    },
    SET_LOADING_PERIODOS: (state, payload) => {
        state.loadingPeriodo = payload
    },
    SET_SHOW_MODAL_PERIODO: (state, payload) => {
        state.show_modal_new_periodo = payload
    },
}

const actions = {
    async fetchPeriodo({commit}, id){
        try{
            commit('SET_LOADING_PERIODOS', true)
            const response = await PeriodoService.getOne(id);
            commit('SET_PERIODO', response.data)
            commit('SET_LOADING_PERIODOS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODOS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPeriodos({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes' }){
        try{
            commit('SET_LOADING_PERIODOS', true)
            const response = await PeriodoService.get(keyword, pageNumber, sortBy);
            commit('SET_PERIODOS', response.data)
            commit('SET_LOADING_PERIODOS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODOS', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async fetchPeriodosByUser({commit}, { keyword = '', pageNumber = 1, sortBy = 'mais recentes', id }){
        try{
            commit('SET_LOADING_PERIODOS', true)
            const response = await PeriodoService.getByUser(keyword, pageNumber, sortBy, id);
            commit('SET_PERIODOS', response.data)
            commit('SET_LOADING_PERIODOS', false)
        } catch (error) {
            commit('SET_LOADING_PERIODOS', false);
            // commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getPeriodo: state => state.periodo,
    getPeriodos: state => state.periodos,
    getLoadingPeriodo: state => state.loadingPeriodo,
    getShowModalNewPeriodo: state => state.show_modal_new_periodo
}

export default {
    state,
    getters,
    mutations,
    actions
};