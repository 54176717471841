import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`categories?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`categories/${id}`);
    },
    create(data) {
        return Api().post(`categories`, data);
    },
    update(data) {
        return Api().put(`categories/${data.id}`, data);
    }
}