import Api from './ApiService'

export default {
    get(keyword, pageNumber, sort) {
        return Api().get(`banner/adm?keyword=${keyword}&pageNumber=${pageNumber}&sort=${sort}`);
    },
    getOne(id) {
        return Api().get(`banner/${id}`);
    },
    create(formData){
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        return Api().post(`banner`, formData, config);
    },
    update(formData){
        const config = {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          };
        return Api().put(`banner/${formData.id}`, formData, config);
    },
    delete(id) {
      return Api().delete(`banner/${id}`);
    }
}