// import Vue from 'vue'
import Vuex from 'vuex'
import helpers from './modules/helpers';
import user from './modules/user';
import projects_autores from './modules/projects_autores';
import projects_disciplinas from './modules/projects_disciplinas';
import category from './modules/category';
import avaliacao from './modules/avaliacao';
import aula from './modules/aula';
import autor from './modules/autor';
import disciplina from './modules/disciplina';
import notification from './modules/notification';
import periodos from './modules/periodos';
import banner from './modules/banner';

export default new Vuex.Store({
    modules: {
        helpers,
        user,
        projects_autores,
        projects_disciplinas,
        category,
        avaliacao,
        aula,
        autor,
        disciplina,
        notification,
        periodos,
        banner
    }
})
