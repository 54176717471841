<template>
  <main>
      <div id="app" class="flex h-screen overflow-y-hidden bg-white">
          <SideNav v-if="$store.state.user.user"
              x-transition:enter="transition transform duration-300" 
              x-transition:enter-start="-translate-x-full opacity-30  ease-in" 
              x-transition:enter-end="translate-x-0 opacity-100 ease-out" 
              x-transition:leave="transition transform duration-300"
              x-transition:leave-start="translate-x-0 opacity-100 ease-out" 
              x-transition:leave-end="-translate-x-full opacity-0 ease-in" 
              class="fixed inset-y-0 z-10 flex flex-col flex-shrink-0 w-64 max-h-screen overflow-hidden transition-all transform bg-white border-r shadow-lg lg:z-auto lg:static lg:shadow-none" :class="{'-translate-x-full lg:translate-x-0 lg:w-16': !getshowSideNav}"/>
          <div :class="getAlerts.length > 0 && 'space-y-1 py-1 __alert shadow-sm'" class="fixed top-0 w-full" style="z-index: 999;">
              <AlertComponent v-for="(alert, index) in getAlerts" :key="index" :alert="alert"/>
          </div>
             <router-view class="flex flex-col w-full flex-1 h-full overflow-x-hidden overflow-y-auto"/>
      </div>
  </main>
</template>

<script>
import { mapGetters } from 'vuex'
import AlertComponent from '@/components/helper/AlertComponent.vue'
import SideNav from '@/components/SideNav';

export default {
  name: 'App',
  components: {
      AlertComponent,
      SideNav
  },
  watch: {
      '$store.state.helpers.hideScrollBar': function(){
          if(this.$store.state.helpers.isMenuOpen){
              document.documentElement.style.overflow = 'hidden'
          }else{
              document.documentElement.style.overflow = null
          }
      }
  },
  computed: {
      ...mapGetters(['getAlerts', 'getshowSideNav']),
  }
}
</script>

<style>
  #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
    }
    * {
      padding: 0;
      margin: 0;
      box-sizing: border-box;
    }
  
    html, body{
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
  
    body {
        display: flex;
        flex-direction: column;
        background-color: rgb(243 244 246);
    }
    .footer{
        flex-shrink: 0;
    }
  
    /* SCROLLBAR */
    ::-webkit-scrollbar { /* width */
    width: 6px;
    }
    ::-webkit-scrollbar-track { /* Track */
    background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb { /* Handle */
    background: #888;
    }
    ::-webkit-scrollbar-thumb:hover { /* Handle on hover */
    background: #555;
    }
    /* FIM SCROLLBAR */

    li {
      list-style-type: none;
    }
      
    </style>
